import moment from 'moment';
import React from "react";
import NumberFormat from "react-number-format";
import parkings from "../redux/reducers/parkings";
import {Chip, Tooltip} from "@material-ui/core";
import NotSetIcon from "@material-ui/icons/ContactSupport";
import OKStateIcon from "@material-ui/icons/CheckCircleOutline";
import NotConnectedStateIcon from "@material-ui/icons/CloudOff";

// Capitalization of string
export const capitalize = (str) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

export const logLevelType = {
    NOTSET: {level: 0, name: '', cls: 'text-muted'},
    DEBUG: {level: 10, name: 'DEBUG', cls: 'text-info'},
    INFO: {level: 20, name: 'INFO', cls: 'text-dark'},
    WARNING: {level: 30, name: 'WARNING', cls: 'text-warning'},
    ERROR: {level: 40, name: 'ERROR', cls: 'text-danger'},
    FATAL: {level: 50, name: 'FATAL', cls: 'text-danger'},
    CRITICAL: {level: 50, name: 'CRITICAL', cls: 'text-danger'},
};

export const SIP_STATUS = {
    CONNECTED: {icon: '', name: 'Connected', color: 'green'},
    DISCONNECTED: {icon: '', name: 'Disconnected', color: 'red'},
    PAUSED: {icon: '', name: 'Paused', color: 'orange'},
    NEW_CALL: {icon: '', name: 'Incoming Call...', color: 'green'},
    NEW_OUT_GOING_CALL: {icon: '', name: 'Outgoing Call...', color: 'green'},
    IN_CALL: {icon: '', name: 'In Call', color: 'green'},

};

// Get task state description
export const getState = (status, t) => {
    switch (status) {
        case 'CR':
            return t('CREATED');
        case 'AS':
            return t('ASSIGNED');
        case 'CO':
            return t('COMPLETED');
        case 'CA':
            return t('CANCELED');
        default:
            return '';
    }
};

// Get code list items from code lists by code
export const getCodeList = (allCodeLists, code) => {
    const codeListIndex = allCodeLists.findIndex((codeList) => codeList.code === code);
    if (codeListIndex === -1) {
        return [];
    }

    return allCodeLists[codeListIndex].items;
};

// Create event host object for select
export const createHosts = (host) => ({
    id: host.parkingHostId,
    value: `event_host_${host.parkingHostId}`,
    label: host.hostName || `event_host_${host.parkingHostId}`,
    key: `event_host_${host.parkingHostId}`,
});

// Get hosts from parkingTasks by parkingHostId
export const getHostItems = (parkingTasks) => {
    const hostIds = [];
    const tasks = [];
    parkingTasks.forEach((task) => {
        if (hostIds.indexOf(task.parkingHostId) === -1) {
            tasks.push(task);
            hostIds.push(task.parkingHostId);
        }
    });

    return tasks.map((task) => createHosts(task));
};

// Create host object for select
export const createHostItem = (host) => ({
    id: host.parkingHostId,
    value: `host_${host.parkingHostId}`,
    label: host.hostName || `host_${host.parkingHostId}`,
    key: `host_${host.parkingHostId}`,
});

// Check task assigned state with creationDate
export const checkAssigned = (task) => {
    if (task.status && task.status === 'CR') {
        return new Date().getTime() - task.creationDateTS <= 1800000;
    }

    return true;
};

// Create zone object for select
export const createZone = (zone) => ({
    zoneId: zone.zoneId,
    value: `zone_${zone.zoneId}`,
    label: zone.zoneName || `zone_${zone.zoneId}`,
    key: `zone_${zone.zoneId}`,
    occupancy: zone.occupancy,
});

// Get cards by cardNumber
export const getCards = (cardZones) => {
    const cardNumbers = [];
    const cards = [];
    cardZones.forEach((cz) => {
        if (cardNumbers.indexOf(cz.cardNumber) === -1) {
            cards.push(cz);
            cardNumbers.push(cz.cardNumber);
        }
    });

    return cards;
};

// Get card table data from cardZones
export const getCardData = (cards) => getCards(cards)
    .map((card) => ({
        id: card.cardZoneId,
        parkingId: card.parkingId,
        cardNo: card.cardNumber,
        owner: card.owner,
        ownerId: card.ownerId,
        valid: card.valid,
        blocked: card.blocked,
        validDate: card.validTo,
        validDateTS: card.validToTS,
        exitTimeTS: card.exitTimeTS,
        type: card.type,
        action: 'assign',
    }));

// Create event type object for select
export const createEventType = (event, index) => ({
    value: `event_type_${index}`,
    label: event.description || `event_type_${index}`,
    key: `event_type_${index}`,
});

// Get keyword index list of string
export const getIndexList = (content, keyword) => {
    const keyIndexList = [];
    let result;
    const regex = new RegExp(keyword, 'g');
    /* eslint-disable-next-line no-cond-assign */
    while ((result = regex.exec(content))) {
        keyIndexList.push(result.index);
    }

    return keyIndexList;
};

// Get event description with message and messageParam
export const getEventDescription = (message, messageParams) => {
    let realMessage = message;
    const paramsIndexList = getIndexList(messageParams, ';');
    const msgParamsIndexList = getIndexList(message, '{');

    if (messageParams === -1) {
        return message;
    }

    if (paramsIndexList.length === 0) {
        return realMessage.replace('{1}', messageParams);
    }

    if (msgParamsIndexList.length === 1 && paramsIndexList.length > 0) {
        return realMessage.replace('{1}', messageParams.slice(0, paramsIndexList[0]));
    }

    if (msgParamsIndexList.length > 1 && paramsIndexList.length > 0) {
        for (let i = 0; i < paramsIndexList.length - 1; i++) {
            realMessage = realMessage
                .replace(`{${i + 1}}`, messageParams.slice(paramsIndexList[i] + 1, paramsIndexList[i + 1]));
        }
    }

    return realMessage;
};

// Get parking events filter query
export const getEventsQuery = (dateFrom, dateTo, hostType, eventType, lpn, lastId, size, page, orderedColumnId, orderDirection) => {
    if (!dateFrom && !dateTo && !hostType && !eventType && !lpn && !lastId && !size && !page) {
        return '';
    }

    const dateFromStr = dateFrom ? moment(dateFrom).format('DD.MM.YYYY HH:mm') : null;
    const dateToStr = dateTo ? moment(dateTo).format('DD.MM.YYYY HH:mm') : null;

    let query = '?';
    const queryParams = [dateFromStr, dateToStr, hostType, eventType, lpn, lastId, size, page, orderedColumnId, orderDirection];
    const queryBefore = ['dateFrom=', 'dateTo=', 'hostType=', 'eventType=', 'lpn=', 'lastId=', 'size=', 'page=', 'orderedColumnId=', 'orderDirection='];

    for (let i = 0; i < queryParams.length; i++) {
        if (queryParams[i] || queryParams[i] === 0) {
            query += `${queryBefore[i]}${queryParams[i]}&`;
        }
    }

    return query.slice(0, query.length - 1);
};

// Get parking events filter query
export const getFulltextQuery = (hosts, parkings, search, dateFrom, dateTo, lpn, cardNumber, owner) => {
    if (!hosts && !parkings && !dateFrom && !dateTo && !search && !lpn && !cardNumber && !owner) {
        return '';
    }


    const hostStr = hosts.length > 0 ? hosts.map(a => a.parkingHostId).join(',') : null;
    const parkingStr = parkings.length > 0 ? parkings.map(a => a.parkingId).join(',') : null;
    const dateFromStr = dateFrom ? moment(dateFrom).utc().format() : null;
    const dateToStr = dateTo ? moment(dateTo).utc().format() : null;

    let query = '?';
    const queryParams = [hostStr, parkingStr, dateFromStr, dateToStr, search, lpn, cardNumber, owner];
    const queryBefore = ['hostsId=', 'parkingsId=', 'dateFrom=', 'dateTo=', 'search=', 'searchLpn=', 'searchNumber=', 'searchOwner='];

    for (let i = 0; i < queryParams.length; i++) {
        if (queryParams[i] || queryParams[i] === 0) {
            query += `${queryBefore[i]}${queryParams[i]}&`;
        }
    }

    return query.slice(0, query.length - 1);
};


// Get parking tasks filter query
export const getTasksQuery = (createdById, hostType, state, size, page, keyword) => {
    if (!createdById && !hostType && !state && !size && !page && !keyword) {
        return '';
    }

    let query = '?';
    const queryParams = [createdById, hostType, state, size, page, keyword];
    const queryBefore = ['createdById=', 'hostType=', 'state=', 'size=', 'page=', 'keyword='];

    for (let i = 0; i < queryParams.length; i++) {
        if (queryParams[i] || queryParams[i] === 0) {
            query += `${queryBefore[i]}${queryParams[i]}&`;
        }
    }

    return query.slice(0, query.length - 1);
};

// Get tasks statistic filter query
export const getTasksStatisticQuery = (dateFrom, dateTo) => {
    if (!dateFrom && !dateTo) {
        return '';
    }

    const dateFromStr = dateFrom ? moment(dateFrom).format('DD.MM.YYYY') : null;
    const dateToStr = dateTo ? moment(dateTo).format('DD.MM.YYYY') : null;

    let query = '?';
    const queryParams = [dateFromStr, dateToStr];
    const queryBefore = ['dateFrom=', 'dateTo='];

    for (let i = 0; i < queryParams.length; i++) {
        if (queryParams[i] || queryParams[i] === 0) {
            query += `${queryBefore[i]}${queryParams[i]}&`;
        }
    }

    return query.slice(0, query.length - 1);
};

export const getCardsQueryForProductExecution = (validTill, validity, blocked, lpn, cardNumber, size, page) => {
    let query = `?size=${size}`;
    query += `&page=${page}`;

    if (validTill) {
        const validTillStr = moment(validTill).format('DD.MM.YYYY HH:mm');
        query += `&validTill=${validTillStr}`;
    }

    if (lpn && lpn.trim().length > 0) {
        query += `&lpn=${lpn}`;
    }

    if (cardNumber && cardNumber.trim().length > 0) {
        query += `&cardNumber=${cardNumber}`;
    }

    if (validity === 'valid') {
        query += `&validity=${true}`;

    } else if (validity === 'invalid') {
        query += `&validity=${false}`;

    }

    if (blocked === 'blocked') {
        query += `&blocked=${true}`;

    } else if (blocked === 'unblocked') {
        query += `&blocked=${false}`;
    }


    return query;
}


// Get parking cards filter query
export const getCardsQuery = (owner, validTill, validity, blocked, zone, cardType, size, page, orderedColumnId, orderDirection) => {
    if (!owner && !validTill && !validity && !blocked && !zone && !cardType && !size && !page) {
        return '';
    }

    const validTillStr = validTill ? moment(validTill).format('DD.MM.YYYY HH:mm') : null;

    let query = '?';
    const queryParams = [owner, validTillStr, validity, blocked, zone, cardType, size, page, orderedColumnId, orderDirection];
    const queryBefore = ['owner=', 'validTill=', 'validity=', 'blocked=', 'zoneId=', 'cardType=', 'size=', 'page=', 'orderedColumnId=', 'orderDirection='];

    for (let i = 0; i < queryParams.length; i++) {
        if (queryParams[i] || queryParams[i] === 0) {
            query += `${queryBefore[i]}${queryParams[i]}&`;
        }
    }

    return query.slice(0, query.length - 1);
};

export const getOwnersQuery = (validity, search, size, page) => {
    if (!size && !page) {
        return '';
    }

    let reqQuery = '?';
    const queryParams = [validity, search, size, page];
    const queryBefore = ['valid=', 'query=', 'size=', 'page='];

    for (let i = 0; i < queryParams.length; i++) {
        if (queryParams[i] || queryParams[i] === 0) {
            reqQuery += `${queryBefore[i]}${queryParams[i]}&`;
        }
    }

    return reqQuery.slice(0, reqQuery.length - 1);
};

// Get chip background color
export const getChipColor = (color) => {
    switch (color.toLowerCase()) {
        case 'red':
            return '#FBC1BA';
        case 'yellow':
            return '#FFDA98';
        case 'blue':
            return '#B6D3F9';
        default:
            return color;
    }
};

function invertColor(hex) {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    // invert color components
    var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
        g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
        b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    // pad each with zeros and return
    return '#' + padZero(r) + padZero(g) + padZero(b);
}

function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
}


// Get chip font color
export const getChipFontColor = (color) => {
    switch (color.toLowerCase()) {
        case 'red':
            return '#F24735';
        case 'yellow':
            return '#E99708';
        case 'blue':
            return '#5985EE';
        default:
            return color.indexOf('#') > -1 ? invertColor(color) : color;
    }
};

// Get multi edit cards query
export const getBulkQuery = (setting) => {
    let query = '';

    Object.keys(setting).forEach((key) => {
        if (setting[key] !== null || setting[key] !== undefined) {
            query += `&${key}=${setting[key]}`;
        }
    });

    return query;
};

// Get ownerItems
export const getOwnerItems = (owners) => (owners.length > 0 ? owners.map((owner) => ({
    id: owner.ownerId,
    value: `owner_${owner.ownerId}`,
    label: `${owner.firstname} ${owner.surname}` || `owner_${owner.ownerId}`,
    key: `owner_${owner.ownerId}`,
})) : []);

// Get zoneItems
export const getZoneItems = (zones) => (zones.length > 0 ? zones.map((zone) => ({
    zoneId: zone.zoneId,
    value: `zone_${zone.zoneId}`,
    label: zone.zoneName || `zone_${zone.zoneId}`,
    key: `zone_${zone.zoneId}`,
})) : []);

// Get cardGroupItems
export const getCardGroups = (groups) => (groups.length > 0 ? groups.map((item) => ({
    value: item.parkingCardGroupName,
    label: item.parkingCardGroupName,
    key: `card_group_${item.parkingCardGroupId}`,
})) : []);

// Get parkingAccesses
export const getParkingAccess = (parkingAccesses) => (parkingAccesses.length > 0 ? parkingAccesses.map((item) => ({
    id: item.parkingAcccesId,
    value: item.parkingAccessName,
    label: item.parkingAccessName,
    key: `parking_access_${item.parkingAcccesId}`,
})) : []);

// Calculate errors counts by hostCode
export const getErrorCounts = (parkingErrors, hostCode) => {
    const errorCounts = {
        criticatError: 0,
        minorError: 0,
    };
    parkingErrors.forEach((error) => {
        if (error.hostCode === hostCode) {
            errorCounts.criticatError += error.criticatError;
            errorCounts.minorError += error.minorError;
        }
    });

    return errorCounts;
};

// Get errors by hostCode
export const getErrors = (parkingErrors) => {
    if (parkingErrors.length === 0) {
        return [];
    }

    const hostCodes = [];
    const errors = [];
    parkingErrors.forEach((error) => {
        if (hostCodes.indexOf(error.hostCode) === -1) {
            errors.push(error);
            hostCodes.push(error.hostCode);
        }
    });

    return errors.map((error) => ({
        ...error,
        criticatError: getErrorCounts(parkingErrors, error.hostCode).criticatError,
        minorError: getErrorCounts(parkingErrors, error.hostCode).minorError,
    }));
};

// Get formatted datetime string
export const getFormattedStr = (dateTime) => (dateTime ? moment(dateTime).format('DD.MM.YYYY HH:mm') : null);

// Get userItems by users
export const getUsers = (users) => {
    if (users.length === 0) {
        return [];
    }

    return users.map((user) => ({
        value: `${user.firstName} ${user.lastName}`,
        label: `${user.firstName} ${user.lastName}`,
        key: user.userId,
    }));
};

export const getTaskHosts = (allCodeList, t) => {
    const hostItems = getCodeList(allCodeList, 'HOST_TYPE');
    return hostItems.length > 0 ? hostItems.map((item) => ({
        value: item.code,
        label: t(item.description),
        key: `host_type_${item.codeListItemId}`,
    })) : [];
};

export const cardDescription = [
    'CMD_CARD_DENYV2',
    'EVT_CARD_ALLOWED_READED',
    'EVT_CARD_ISSUED',
    'EVT_CARD_PASS',
    'EVT_CARD_REJECTED',
    'EVT_GET_CARD_INFO',
];

export const isCardEvent = (desc) => cardDescription.includes(desc);

export const getCardNumber = (message) => {
    const start = message.lastIndexOf('{') + 1;
    const end = message.lastIndexOf('}');
    if (start !== -1 && end !== -1 && start < end) {
        return message.substring(start, end);
    }
};

export const getQueryVariable = (variable) => {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        if (pair[0] === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    return false;
};

export const callsToLocaleStorage = (list) => {
    return list.map(a => {
        return {
            number: a.number,
            reject: (a.reject ? 'Y' : 'N'),
            accepted: (a.accepted ? 'Y' : 'N'),
            out: (a.out ? 'Y' : 'N'),
            missed: (a.missed ? 'Y' : 'N'),
            date: (a.date ? a.date.getTime() : null),
        }
    });
};

export const callsFromLocaleStorage = (list) => {
    if (list) {
        return list.map(a => {
            return {
                number: a.number,
                reject: (a.reject === 'Y' ? true : false),
                accepted: (a.accepted === 'Y' ? true : false),
                out: (a.out === 'Y' ? true : false),
                missed: (a.missed === 'Y' ? true : false),
                date: (a.date ? new Date(a.date) : null),
            }
        });
    }
}


export const memorySizeOf = (obj) => {
    let bytes = 0;

    function sizeOf(obj) {
        if (obj !== null && obj !== undefined) {
            switch (typeof obj) {
                case 'number':
                    bytes += 8;
                    break;
                case 'string':
                    bytes += obj.length * 2;
                    break;
                case 'boolean':
                    bytes += 4;
                    break;
                case 'object':
                    var objClass = Object.prototype.toString.call(obj).slice(8, -1);
                    if (objClass === 'Object' || objClass === 'Array') {
                        for (const key in obj) {
                            if (!obj.hasOwnProperty(key)) continue;
                            sizeOf(obj[key]);
                        }
                    } else bytes += obj.toString().length * 2;
                    break;
            }
        }
        return bytes;
    }

    function formatByteSize(bytes) {
        if (bytes < 1024) return `${bytes} bytes`;
        if (bytes < 1048576) return `${(bytes / 1024).toFixed(3)} KiB`;
        if (bytes < 1073741824) return `${(bytes / 1048576).toFixed(3)} MiB`;
        return `${(bytes / 1073741824).toFixed(3)} GiB`;
    }

    return formatByteSize(sizeOf(obj));
};


export const isComponentVisibleForUser = (componentUseCase, userUseCases) => {
    return userUseCases[componentUseCase];
};

export const isComponentEnabledForUser = (componentUseCase, userUseCases) => {
    return userUseCases[componentUseCase] && userUseCases[componentUseCase].execute;
};


export const numberFormat0 = (props) => {
    const {inputRef, onChange, ...other} = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={''}
            decimalSeparator={'.'}
            allowNegative={false}
            isNumericString={false}
            decimalScale={0}
            fixedDecimalScale={true}
            inputMode="decimal"
            pattern="[0-9],*"
        />
    );
};

export const numberFormat1 = (props) => {
    const {inputRef, onChange, ...other} = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={''}
            decimalSeparator={'.'}
            allowNegative={false}
            isNumericString={false}
            decimalScale={1}
            fixedDecimalScale={true}
            inputMode="decimal"
            pattern="[0-9],*"
        />
    );
};

export const numberFormat2 = (props) => {
    const {inputRef, onChange, ...other} = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={''}
            decimalSeparator={'.'}
            allowNegative={false}
            isNumericString={false}
            decimalScale={2}
            fixedDecimalScale={true}
            inputMode="decimal"
            pattern="[0-9],*"
        />
    );
};

export const numberFormat3 = (props) => {
    const {inputRef, onChange, ...other} = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={''}
            decimalSeparator={'.'}
            allowNegative={false}
            isNumericString={false}
            decimalScale={3}
            fixedDecimalScale={true}
            inputMode="decimal"
            pattern="[0-9],*"
        />
    );
};


export const numberFormat4 = (props) => {
    const {inputRef, onChange, ...other} = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={''}
            decimalSeparator={'.'}
            allowNegative={false}
            isNumericString={false}
            decimalScale={4}
            fixedDecimalScale={true}
            inputMode="decimal"
            pattern="[0-9],*"
        />
    );
};


export const numberFormatPriceCustom = (props) => {
    const {inputRef, onChange, ...other} = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={''}
            decimalSeparator={'.'}
            allowNegative={true}
            isNumericString={false}
            decimalScale={2}
            fixedDecimalScale={true}
            inputMode="decimal"
            pattern="[0-9],*"
        />
    );
};

export const numberFormatPriceCustomPositive = (props) => {
    const {inputRef, onChange, ...other} = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={''}
            decimalSeparator={'.'}
            allowNegative={false}
            isNumericString={false}
            decimalScale={2}
            fixedDecimalScale={true}
            pattern="[0-9],*"
        />
    );
};


export const numberFormatPriceCustomPositiveBig = (props) => {
    const {inputRef, onChange, ...other} = props;
    return (
        <NumberFormat
            {...other}
            style={{fontSize: '35px'}}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            // isNumericString={' '}
            decimalSeparator={'.'}
            allowNegative={false}
            isNumericString={false}
            decimalScale={2}
            fixedDecimalScale={true}
            inputMode="decimal"
            pattern="[0-9],*"
        />
    );
};


// Get task state description
export const getErrorMessage = (error, t) => {
    const errorCode = error && error.response && error.response.data ? error.response.data.errorCode : 'unknown error! Please try again later';
    const params = error && error.response && error.response.data ? error.response.data.errorParams : [];
    const tparams = {};
    if (params && params.length > 0) {
        for (let i = 0; i < params.length; i++) {
            tparams[`param${i + 1}`] = params[i];
        }
    }
    return t(errorCode, tparams);
};

// Get task state description
export const getErrorMessage2 = (error, t) => {
    const errorCode = error && error.data ? error.data.errorCode : 'unknown error! Please try again later';
    const params = error && error.data ? error.data.errorParams : [];
    const tparams = {};
    if (params && params.length > 0) {
        for (let i = 0; i < params.length; i++) {
            tparams[`param${i + 1}`] = params[i];
        }
    }
    return t(errorCode, tparams);
};

export const hasStream = (host) => {
    return host && host.parkingHostId && host.cameraUrl;
}


const getHostCoordinate = (host, columns, rows) => {
    let x = host.column || 0;
    let y = host.row || 0;
    x = x >= columns ? columns - 1 : x < 0 ? 0 : x;
    y = y >= rows ? rows - 1 : y < 0 ? 0 : y
    return {x, y};
}

export const getGridSize = (parkingHosts) => {
    let maxColumnIndex = 0;
    let maxRowIndex = 0;
    for (let i = 0; i < parkingHosts.length; i++) {
        if (parkingHosts[i].column && parkingHosts[i].column > maxColumnIndex) {
            maxColumnIndex = parkingHosts[i].column;
        }
        if (parkingHosts[i].row && parkingHosts[i].row > maxRowIndex) {
            maxRowIndex = parkingHosts[i].row;
        }
    }
    return {maxColumn: maxColumnIndex + 1, maxRow: maxRowIndex + 1};
}

export const getHostLayout = (parkingHosts, gridSize) => {
    const inColision = [];
    const layout = [];
    let row = Math.floor(parkingHosts.length / gridSize.column);
    if (parkingHosts.length % gridSize.column !== 0) {
        row = row + 1;
    }
    if (row < gridSize.row) {
        row = gridSize.row;
    }
    const gridItemCount = row * gridSize.column;
    for (let i = 0; i < gridItemCount; i++) {
        layout.push({
                y: Math.floor(i / gridSize.column),
                x: i % gridSize.column,
                w: 1,
                h: 1,
                id: `empty${i}`,
                isResizable: false,
            }
        );
    }
    //place host if its position is not occupied yet.
    for (let i = 0; i < parkingHosts.length; i++) {
        const coordinate = getHostCoordinate(parkingHosts[i], gridSize.column, row);
        const indexInLayout = layout.findIndex(a => ((a.x == coordinate.x) && (a.y == coordinate.y)));
        if (indexInLayout === -1) {
            debugger
        }
        const occupied = !layout[indexInLayout].id.startsWith('empty');
        if (occupied) {
            inColision.push(parkingHosts[i]);
        } else {
            layout[indexInLayout].id = `${parkingHosts[i].parkingHostId}`;
        }
    }
    //place hosts which were in colision before
    for (let i = 0; i < inColision.length; i++) {
        for (let j = 0; j < layout.length; j++) {
            const empty = layout[j].id.startsWith('empty');
            if (empty) {
                layout[j].id = `${inColision[i].parkingHostId}`;
                break;
            }
        }
    }
    return layout;
}


export function getProductName(product) {
    if (product && product.productSystemParam) {
        let o = product.productSystemParam.find(a => a.paramName === 'name');
        if (o) {
            return o.valueVarchar;
        }
    }
    return '';
}

export const renderConnectionStatus = (protocol, communication, classes, t) => {
    if (!protocol)
        return <Tooltip title={t('PROTOCOL_NOT_SET')}><NotSetIcon/></Tooltip>

    return communication === 'OK' ?
        <Tooltip title={t('CONNECTED')}><Chip
            icon={<OKStateIcon/>}
            label={protocol}
            color="primary" className={classes.connected}/></Tooltip>
        :
        <Tooltip title={t('DISCONNECTED')}><Chip
            icon={<NotConnectedStateIcon/>}
            label={protocol}
            color="inherit" variant="outlined"/></Tooltip>
}

export const isAdmin = (user) => {
    let admin = false;
    if ( user && user.roles && user.roles.length > 0 ) {
        for ( let i=0; i<user.roles.length; i++ ) {
            if ( user.roles[i].admin ) {
                admin = true;
            }
        }
    }
    return admin;
}