import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {CustomButton, CustomSwitch, CustomTabs} from 'components/elements';
import {getErrorMessage, isComponentEnabledForUser, isComponentVisibleForUser} from 'utils';
import {fetchAllProductsForCashDesk, fetchAllProductTemplates,} from 'redux/actions/products';
import {okReceipt, cancelReceipt, changeCurrency, deleteReceiptItem, executeProduct, payReceipt, payCardReceipt} from 'redux/actions/receipts';
import {fetchAllCurrencies, fetchAllRates} from 'redux/actions/currencies';
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import {blue} from "@material-ui/core/colors";
import ProductCategories from './ProductCategories';
import ProductCashDesk from 'components/modals/Product/ProductCashDesk';
import ReceiptDetail from 'components/common/ReceiptDetail';
import Payment from 'components/modals/Product/Payment';
import Error from 'components/modals/Product/Payment/Error';
import ReceiptItemDetail from "../../../components/modals/Product/Receipt/ReceiptItemDetail";
import {processAction} from "../../../redux/actions/parkings";


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        minHeight: '100%',
    },
    receiptContainer: {
        display: 'flex',
        flexBasis: '33%',
        height: '100%',
        minHeight: '100%',
        flexDirection: 'column',
    },
    receiptItems: {
        display: 'flex',
        width: '100%',
        height: '80%',
        overflowY: 'scroll',
        flexDirection: 'column',
    },
    buttonBar: {
        display: 'flex',
        width: '100%',
        height: '100px',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    receiptItem: {
        display: 'flex',
        width: '100%',
        height: '50px',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '5px',
    },
    removeIcon: {
        flexGrow: 1,
        alignItems: 'center',
        height: 30,
    },
    desc: {
        flexGrow: 5,
        alignItems: 'flex-start',
        fontWeight: '500',
    },
    vat: {
        flexGrow: 1,
        alignItems: 'center',
    },
    price: {
        flexGrow: 1,
        alignItems: 'center',
        fontWeight: '500',
    },
    left: {
        flexGrow: 7,
        alignItems: 'center',
        fontWeight: 'bold',
    },
    right: {
        flexGrow: 1,
        alignItems: 'center',
    },
    rightBold: {
        flexGrow: 1,
        alignItems: 'center',
        fontWeight: 'bold',
    },
    productSection: {
        marginLeft: theme.spacing(4),
        display: 'flex',
        flexBasis: '66%',
        flexDirection: 'column',
        height: 'calc(100% - 85px)',
        minHeight: 'calc(100% - 85px)',
    },
    switch: {
        display: 'flex',
        alignItems: 'center',
    },
    switchLabel: {
        marginRight: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
    },
    tabContent: {
        height: '100%',
    },
    actionButton: {
        width: '48%',
        height: '80%',
        fontSize: 25,
    },
    separator: {
        display: 'flex',
        width: '100%',
        height: 5,
        borderBottom: '1px dashed #ADB0B8',
    },


}));


const Card = ({title, tooltip, badgeContent, onClick}) => {
    const classes = useStyles();
    const smallTitle = title && title.length > 20;

    return (
        <Grid item xs={12} sm={6} md={3}>
            <div className={classes.card}>
                <Badge showZero badgeContent={badgeContent} color="error"/>
                <div className={classes.cardContent} onClick={onClick}>
                    <Typography variant={!!smallTitle ? "h4" : "h3"} display="block">{title}</Typography>
                </div>
                <div className={classes.cardTooltip}>
                    <Tooltip disableFocusListener title={tooltip}>
                        <InfoIcon style={{color: blue[500]}}/>
                    </Tooltip>
                </div>
            </div>
        </Grid>
    );
};

function MainCashDesk(props) {
    const {
        userUseCases,
        products,
        productTemplates,
        fetchAllProductsForCashDesk,
        fetchAllCurrencies,
        currencies,
        rates,
        fetchAllRates,
        fetchAllProductTemplates,
        isFetchingAllList,
        executeProduct,
        receipt,
        cancelReceipt,
        okReceipt,
        payReceipt,
        payCardReceipt,
        deleteReceiptItem,
        paymentError,
        changeCurrency,
        cardNumber,
        action,
        processAction,
        cashDeskActiveTab,
    } = props;


    const classes = useStyles();

    const {t} = useTranslation();

    const [isFetchedProducts, setIsFetchedProducts] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingCancle, setIsLoadingCancle] = useState(false);
    const [changingLayout, setChangingLayout] = useState(false);
    const [errorDetailOpen, setErrorDetailOpen] = useState(false);
    const [currenciesValues, setCurrenciesValues] = useState([]);
    const [tabContents, setTabContents] = useState([]);
    const [tabs, setTabs] = useState([]);
    const [aggregated, setAggregated] = useState(false);
    const [productDetailModel, setProductDetailModel] = useState({
        opened: false,
        product: undefined,
        title: '',
        withCard: false,
        cardNumberRead: undefined,
        sameGroupAndTypeProducts: []
    });
    const [paymentDetailOpen, setPaymentDetailOpen] = useState(false);
    const [receiptItemProductDetail, setReceiptItemProductDetail] = useState();
    const [receiptItemProductDetailOpened, setReceiptItemProductDetailOpened] = useState(false);
    const [ccState, setCcState] = useState('');
    const [cardDefault, setCardDefaut] = useState(false);

    useEffect(() => {
        if (!isFetchedProducts) {
            //2020-10-31
            fetchAllProductsForCashDesk(localStorage.getItem('pos_id')
            ).then(
                () => {
                    fetchAllCurrencies().then(() => {
                        fetchAllProductTemplates().then(() => {
                            cancelReceipt(localStorage.getItem('pos_id')).then(() => {
                                // if ( receipt && receipt.state === 'PAY_IN_PROGRESS' ) {
                                //     setCardDefaut(true);
                                //     setCcState('TIMEOUT');
                                //     setPaymentMessage(t('PAYMENT_TIMEOUT2'));
                                //     setPaymentDetailOpen(true);
                                // }

                                setIsLoadingCancle(true);
                                fetchAllRates().then(() => {
                                    setIsLoading(false);
                                }).catch((err) => {
                                    setIsLoading(false);
                                });
                            })
                        }).catch((err) => {
                            setIsLoading(false);
                        });
                    }).catch((err) => {
                        setIsLoading(false)
                    });
                }
            ).catch((err) => {
                setIsLoading(false)
            });
        }

    }, [fetchAllProductsForCashDesk, fetchAllRates, fetchAllCurrencies, fetchAllProductTemplates, isFetchedProducts]);


    const [activeTab, setActiveTab] = useState(0);
    const handleChangeTab = (value) => {
        setActiveTab(value);
    };
    const checkActivated = (value) => {
        if (activeTab === -1) return true;

        return value === activeTab;
    };


    const onItemClick = (item) => {
        if (item && item.runningProduct) {
            setReceiptItemProductDetail(item);
            setReceiptItemProductDetailOpened(true);
        }
    }

    const closeReceiptItemProductDetail = () => {
        setReceiptItemProductDetailOpened(false);
    }


    useEffect(() => {
        if (currencies) {
            setCurrenciesValues(currencies.map(a => {
                return {value: a.code, label: a.code, key: a.code}
            }));
        }
    }, [currencies]);


    const getProductSystemParam = (updatedProduct, systemParamName, systemParamType) => {
        if (updatedProduct && updatedProduct.productSystemParam) {
            let property = updatedProduct.productSystemParam.find(a => a.paramName === systemParamName);
            if (property) {
                return property[systemParamType];
            }
        }
    };

    useEffect(() => {
            if (cardNumber && products && products.length > 0 && (cashDeskActiveTab === 0)) {

                if (productDetailModel.opened) {
                    handleProductDetail(productDetailModel.product, cardNumber, productDetailModel.continueProducts);
                } else {
                    const productsCopy = [...products];
                    productsCopy.sort(
                        (p1, p2) => {
                            const value1 = getProductSystemParam(p1, 'readOrder', 'valueVarchar');
                            const value2 = getProductSystemParam(p2, 'readOrder', 'valueVarchar');
                            if (value1 && !value2) {
                                return -1;
                            }
                            if (!value1 && value2) {
                                return 1;
                            }
                            if (!value1 && !value2) {
                                return 0;
                            }
                            return value1 - value2;
                        }
                    )

                    let productWithLowestCardRead = productsCopy[0];
                    let lowestCardRead = getProductSystemParam(productWithLowestCardRead, 'readOrder', 'valueVarchar');
                    if (lowestCardRead) {
                        const continueProducts = [];
                        let readerOrderContinue = getProductSystemParam(productWithLowestCardRead, 'readerOrderContinue', 'valueBoolean');
                        if (readerOrderContinue && productsCopy.length > 1) {
                            for (let i = 1; i < productsCopy.length; i++) {
                                const lowestCardRead1 = getProductSystemParam(productsCopy[i], 'readOrder', 'valueVarchar');
                                const readerOrderContinue1 = getProductSystemParam(productsCopy[i], 'readerOrderContinue', 'valueBoolean');
                                if (lowestCardRead1) {
                                    continueProducts.push(productsCopy[i]);
                                } else {
                                    break;
                                }
                                if (!readerOrderContinue1) {
                                    break;
                                }

                            }
                        }
                        handleProductDetail(productWithLowestCardRead, cardNumber, continueProducts);
                    }
                }
                processAction();
            }
        }, [cardNumber, action]
    );


    const closeProductDetail = () => {
        setProductDetailModel({
            opened: false,
            product: undefined,
            title: '',
            withCard: false,
            cardNumber: undefined,
            sameGroupAndTypeProducts: []
        });
    }

    const closePaymentDetail = () => {
        setSnackbarOpen(false);
        setPaymentOpen(false);
        setPaymentMessage('');
        setPaymentDetailOpen(false);
    }

    const closeErrorDetail = () => {
        setErrorDetailOpen(false);
    }


    const handleProductDetail = (product, cardNumber, continueProducts) => {
        let withCard = false;
        const template = productTemplates.find(a => a.productTemplateId === product.productTemplateId);
        if (template) {
            withCard = template.withCard;
        }

        setProductDetailModel({
            continueProducts: continueProducts,
            aggregated: aggregated,
            opened: true,
            product: product,
            title: getProductName(product),
            withCard: withCard,
            cardNumberRead: cardNumber,
            sameGroupAndTypeProducts: products.filter(a =>
                a.productTemplateId === product.productTemplateId &&
                a.productGroupName === product.productGroupName
            )
        });

    }

    const handlePaymentDetail = () => {
        setPaymentDetailOpen(true);
    }

    const handleCloseReceipt = (paymentStatus) => () => {
        console.log('paymentStatus XXXXX', paymentStatus);
        okReceipt(paymentStatus)
            .then(()=>{
                setPaymentDetailOpen(false);
                setCcState('');
                setCardDefaut(false);
            }).catch((err)=>{
                console.log('okReceipt error', err);
                setPaymentDetailOpen(false);
                setCcState('');
            })
    }

    const execute = (product, cashNo) => {
        executeProduct(product, cashNo, localStorage.getItem('pos_id'))
        .then((prod) => {
            if (productDetailModel && productDetailModel.continueProducts && productDetailModel.continueProducts.length > 0) {
                handleProductDetail(productDetailModel.continueProducts[0], productDetailModel.cardNumberRead, productDetailModel.continueProducts.slice(1));
            } else {
                closeProductDetail();
            }
        }).catch((error) => {
            setError(getErrorMessage(error, t));
            setSnackbarOpen(true);
        });
    };

    const payInner = (rec) => {
        if ( rec.paymentType === 'CARD' ) {
            payCardReceipt(rec).then((data) => {
                    setPaymentOpen(true);
                    setPaymentMessage('Payment processing');
                    // show progress payment
                }
            ).catch((error) => {
                setError(getErrorMessage(error, t));
                setSnackbarOpen(true);
            });
        } else {
            payReceipt(rec, localStorage.getItem('pos_id')).then((data) => {
                    setPaymentDetailOpen(false);
                }
            ).catch((error) => {
                setError(getErrorMessage(error, t));
                setSnackbarOpen(true);
            });
        }
    }

    const cancelError = () => {
        cancelReceipt(localStorage.getItem('pos_id')).then((prod) => {
                setErrorDetailOpen(false);
            }
        );
    };

    useEffect(() => {
        if (products) {
            const tabs1 = [];
            const tabContents1 = [];
            const categories = [t('ALL')];

            if (isComponentVisibleForUser(UC_PRODUCT_GROUPS, userUseCases)) {
                for (let i = 0; i < products.length; i++) {
                    const category = products[i].productGroupName;
                    const alreadyAddess = categories.find(a => a === category);
                    if (!alreadyAddess) {
                        categories.push(category);
                    }
                }
            }

            for (let j = 0; j < categories.length; j++) {
                tabs1.push(categories[j]);
                tabContents1.push(<ProductCategories
                    setChangingLayout={setChangingLayout}
                    activeTab={activeTab}
                    tabIndex={j}
                    aggregated={aggregated}
                    userUseCases={userUseCases}
                    products={products}
                    handleProductDetail={handleProductDetail}
                    productCategory={categories[j]}
                    isLoading={isFetchingAllList}
                />);
            }
            ;

            setTabs(tabs1);
            setTabContents(tabContents1);

        }
        setChangingLayout(false);
    }, [products, isLoading, productTemplates, aggregated, activeTab, changingLayout]);

    useEffect(() => {
        if (paymentError) {
            if (paymentError.receiptItemId) {
                setErrorDetailOpen(true);
            } else {
                setErrorDetailOpen(false);
            }
        }
    }, [paymentError]);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [error, setError] = useState('');

    const [paymentOpen, setPaymentOpen] = useState(false);
    const [paymentMessage, setPaymentMessage] = useState('');


    const [selectedCurrency, setSelectedCurrency] = useState();

    function getProductName(product) {
        if (product && product.productSystemParam) {
            let o = product.productSystemParam.find(a => a.paramName === 'name');
            if (o) {
                return o.valueVarchar;
            }
        }
        return '';
    }

    useEffect(() => {
        console.log('receipt', receipt, isLoading);
        if (receipt) {
            if (!selectedCurrency) {
                setSelectedCurrency(receipt.currency);
            }

            if ( isLoadingCancle && receipt && receipt.state === 'PAY_IN_PROGRESS' ) {
                setCardDefaut(true);
                setCcState('TIMEOUT');
                setPaymentMessage(t('PAYMENT_TIMEOUT2'));
                setPaymentDetailOpen(true);
                setPaymentOpen(true);
            }
        }
    }, [receipt, isLoadingCancle]);


    return (
        <div className={classes.container}>
            <div className={classes.receiptContainer}>
                <div className={classes.buttonBar}>
                    <CustomButton
                        label={t('CANCEL')}
                        className={classes.actionButton}
                        color="red"
                        disabled={!receipt.receiptId}
                        onClick={() => {
                            cancelReceipt(localStorage.getItem('pos_id'))
                        }}
                    />
                    {isComponentVisibleForUser(UC_PAY, userUseCases) &&
                    <CustomButton
                        label={t('PAY')}
                        className={classes.actionButton}
                        disabled={!receipt.receiptId || !isComponentEnabledForUser(UC_PAY, userUseCases)}
                        color="green"
                        onClick={handlePaymentDetail}
                    />}
                </div>

                <ReceiptDetail
                    receipt={receipt}
                    onCurrencyChange={(event) => {
                        setSelectedCurrency(event);
                    }}
                    userUseCases={userUseCases}
                    selectedCurrency={selectedCurrency}
                    rates={rates}
                    currencies={currenciesValues}
                    onDeleteReceiptItem={deleteReceiptItem}
                    onItemClick={onItemClick}
                    editable
                />

            </div>
            <div className={classes.productSection}>
                {isComponentVisibleForUser(UC_PRODUCT_AGG, userUseCases) && <div className={classes.switch}>
                    <CustomSwitch color="secondary" checked={aggregated} handleSwitch={setAggregated}
                                  disabled={!isComponentEnabledForUser(UC_PRODUCT_AGG, userUseCases)}/>
                    <div className={classes.switchLabel}>
                        {t('SHOW_PRODUCT_GROUP')}
                    </div>
                </div>
                }

                <CustomTabs
                    textColor='default'
                    tabs={tabs}
                    scrollable
                    tabContents={tabContents}
                    handleChangeTab={handleChangeTab}
                />
            </div>
            <ProductCashDesk
                productDetailModel={productDetailModel}
                userUseCases={userUseCases}
                save={execute}
                handleClose={closeProductDetail}
                productTemplates={productTemplates}
                snackbarOpen={snackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
                error={error}
                setError={setError}
            />
            <ReceiptItemDetail
                opened={receiptItemProductDetailOpened}
                receiptItemProductDetail={receiptItemProductDetail}
                handleClose={closeReceiptItemProductDetail}
                productTemplates={productTemplates}
                currency={selectedCurrency}
                userUseCases={userUseCases}
            />
            <Payment opened={paymentDetailOpen}
                     receipt={receipt}
                     userUseCases={userUseCases}
                     pay={payInner}
                     handleClose={closePaymentDetail}
                     rates={rates}
                     currencies={currenciesValues}
                     snackbarOpen={snackbarOpen}
                     setSnackbarOpen={setSnackbarOpen}
                     errorPayment={error}
                     paymentOpen={paymentOpen}
                     setPaymentOpen={setPaymentOpen}
                     paymentMessage={paymentMessage}
                     setPaymentMessage={setPaymentMessage}
                     handleCloseReceipt={handleCloseReceipt}
                     ccState={ccState}
                     setCcState={setCcState}
                     cardDefault={cardDefault}
            />
            <Error paymentError={paymentError}
                   opened={errorDetailOpen}
                   handleClose={closeErrorDetail}
                   submit={cancelError}/>

        </div>
    );
}

MainCashDesk.propTypes = {};

const UC_PRODUCT_GROUPS = 'UC0403';
const UC_PRODUCT_AGG = 'UC0404';
const UC_PRODUCT_WIZARD = 'UC0405';
const UC_PAY = 'UC0453';


const mapStateToProps = (store) => ({
    receipt: store.receiptsData.receipt,
    paymentError: store.receiptsData.paymentError,
    products: store.productsData.productsForCashDesk,
    productTemplates: store.productsData.productTemplates,
    isFetchingAllList: store.productsData.isFetchingAllList,
    currencies: store.currenciesData.currencies,
    rates: store.currenciesData.rates,
    cardNumber: store.parkingsData.cardNumber,
    action: store.parkingsData.action,


});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAllProductsForCashDesk,
    fetchAllCurrencies,
    fetchAllRates,
    fetchAllProductTemplates,
    executeProduct,
    payReceipt,
    payCardReceipt,
    cancelReceipt,
    okReceipt,
    deleteReceiptItem,
    changeCurrency,
    processAction,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MainCashDesk);

