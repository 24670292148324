import React, {useEffect, useState} from 'react';
import CustomSelect from "../elements/CustomSelect";
import Box from "@material-ui/core/Box";
import CancelIcon from '@material-ui/icons/Cancel';

import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import InputBase from "@material-ui/core/InputBase";
import {isComponentEnabledForUser, isComponentVisibleForUser, numberFormatPriceCustom} from "../../utils";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";


const PriceInputLeft = withStyles((theme) => ({
    input: {
        fontSize: 18,
        textAlign: 'right',
        color: '#1B1B28',
    },
}))(InputBase);

const PriceInput = withStyles((theme) => ({
    input: {
        fontSize: 18,
        textAlign: 'right',
        color: '#1B1B28',
    },
}))(InputBase);

const PriceInputBig = withStyles((theme) => ({
    input: {
        fontSize: 24,
        textAlign: 'right',
        color: '#1B1B28',
    },
}))(InputBase);


const useStyles = makeStyles((theme) => ({
    receipt: {
        display: 'flex',
        border: `1px solid ${theme.palette.base[500]}`,
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
    },
    receiptItems: {
        display: 'flex',
        width: '100%',
        height: '80%',
        overflowY: 'scroll',
        flexDirection: 'column',
    },
    receiptItem: {
        fontSize: 18,
        display: 'flex',
        width: '100%',
        height: '50px',
        flexDirection: 'row',
        alignItems: 'center',
    },
    receiptItemBig: {
        fontSize: 18,
        display: 'flex',
        width: '100%',
        height: '70px',
        flexDirection: 'row',
        alignItems: 'center',
    },
    removeIcon: {
        flex: 1,
        alignItems: 'center',
        height: 30,
        cursor: 'pointer',
    },
    receiptItemList: {
        flex: 4,
        alignItems: 'flex-start',
        fontWeight: '500',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',

    },
    itemDescription: {
        flex: 4,
        alignItems: 'flex-start',
        fontSize: '12px',
        fontWeight: '100',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',

    },
    vat: {
        flex: 2,
        alignItems: 'center',
    },
    price: {
        flex: 2,
        alignItems: 'center',
        fontWeight: '500',
        paddingRight: '5px',
    },
    leftNormal: {
        fontSize: 18,
        width: '50%',
        alignItems: 'left',
    },
    left: {
        fontSize: 18,
        width: '50%',
        alignItems: 'left',
        fontWeight: 'bold',
    },
    leftBig: {
        width: '50%',
        alignItems: 'left',
        fontWeight: 'bold',
        fontSize: 24,
    },
    currency: {
        fontSize: 18,
        width: '10%',
        paddingLeft: '5px',
    },
    currencyBold: {
        fontSize: 18,
        width: '10%',
        fontWeight: 'bold',
        paddingLeft: '5px',
    },
    currencyBoldBig: {
        fontSize: 24,
        width: '10%',
        fontWeight: 'bold',
        paddingLeft: '5px',
    },

    right: {
        width: '50%',
    },
    rightBold: {
        width: '50%',
        fontWeight: 'bold',
    },
    rightCurrency: {
        fontSize: 18,
        color: '#1B1B28',
    },
    rightBoldCurrency: {
        fontSize: 24,
        color: '#1B1B28',
    },
    rightBoldBig: {
        width: '50%',
        fontWeight: 'bold',
        fontSize: 24,
    },
    priceValue: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    select: {
        '& .MuiSelect-select': {
            paddingLeft: theme.spacing(1.125),
            lineHeight: '25px',
        },
    },
    content: {
        flex: 1,
        overflow: 'hidden',

        '& .MuiSelect-select': {
            borderBottom: '1px solid #ADB0B8',
            fontSize: 16,
            color: theme.palette.base[800],
        },
        '& svg': {
            width: 24,
            height: 24,
            fill: theme.palette.base[300],
            marginTop: 0,
        },

        '& > div': {
            display: 'flex',
            flex: 1,
            marginLeft: 0,
        },
    },
    separator: {
        display: 'flex',
        width: '100%',
        height: '5px',
        borderBottom: '1px dashed #ADB0B8',
    },


}));

export default ({receipt, currencies, onCurrencyChange, editable, onDeleteReceiptItem, selectedCurrency, rates, onItemClick, userUseCases, ...props}) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const [vat, setVat] = useState({});
    const [noVat, setNoVat] = useState(0);
    const [rate, setRate] = useState(1);


    useEffect(() => {

            if (rates && selectedCurrency && receipt) {
                if (selectedCurrency === receipt.currency) {
                    setRate(1);
                    return;
                }
                let found = false;
                for (let i = 0; i < rates.length; i++) {
                    if ((rates[i].currencyFrom === receipt.currency) && (rates[i].currencyTo === selectedCurrency)) {
                        setRate(rates[i].rate);
                        return;
                    } else if ((rates[i].currencyTo === receipt.currency) && (rates[i].currencyFrom === selectedCurrency)) {
                        setRate(1 / rates[i].rate);
                        return;
                    }
                }
                setRate(undefined);
            }
        }, [selectedCurrency, rates, receipt]
    );

    useEffect(() => {
        if (receipt && receipt.receiptItems) {
            const newVat = {};
            let noVat = 0;
            for (let i = 0; i < receipt.receiptItems.length; i++) {
                const vatCharge = receipt.receiptItems[i].vatCharge || 'Unknown';
                let sumVatCharge = newVat[vatCharge] || 0;
                sumVatCharge += (receipt.receiptItems[i].priceWithVAT || 0);
                noVat += (100 - vatCharge) * (receipt.receiptItems[i].priceWithVAT || 0) / 100;
                newVat[vatCharge] = sumVatCharge;
            }
            setNoVat(noVat);
            setVat(newVat);
        } else {
            setNoVat(0);
            setVat({});
            setRate(1);
        }
    }, [receipt]);


    if (!receipt) {
        return null; //TODO?
    }

    return (
        <div className={classes.receipt}>
            <div className={classes.receiptItems}>
                {receipt && receipt.receiptItems &&
                receipt.receiptItems.map(item => {
                    return (<div className={classes.receiptItem} key={item.receiptItemId}>
                            {!!editable && isComponentVisibleForUser(UC_ITEM_REMOVE, userUseCases)  ? (<div className={classes.removeIcon}>
                                <CancelIcon style={{fontSize: 30}}
                                            onClick={onDeleteReceiptItem && isComponentEnabledForUser(UC_ITEM_REMOVE, userUseCases)  && (() => onDeleteReceiptItem(item.receiptItemId))}
                                            color="error"/>
                            </div>) : null}
                            <div className={classes.receiptItemList} onClick={()=>{onItemClick && onItemClick(item);}}>
                                <div> {item.receiptItemName} </div>
                                <div className={classes.itemDescription} >{item.description} </div>
                            </div>
                            <div className={classes.vat}>{`${item.vatCharge} %`}</div>
                            <div className={classes.price}>
                                <PriceInputLeft
                                    fullWidth={true}
                                    disabled={true}
                                    value={item.priceWithVAT * rate}
                                    inputComponent={numberFormatPriceCustom}
                                />
                            </div>

                        </div>
                    );
                })
                }
            </div>
            <div className={classes.receiptItem}>
                <Box className={classes.content}>
                    {isComponentVisibleForUser(UC_CURRENCY_CHANGE, userUseCases) &&    <CustomSelect
                        disabled={!receipt.receiptId || !!editable|| !isComponentEnabledForUser(UC_CURRENCY_CHANGE, userUseCases) }
                        items={currencies || []}
                        value={selectedCurrency}
                        placeholder={t('CURRENCY')}
                        className={classes.select}
                        handleChange={onCurrencyChange}
                    />}
                </Box>
            </div>
            <div className={classes.separator}/>
            <div className={classes.receiptItemBig}>
                <div className={classes.left}>{t('TOTAL_NO_VAT')}</div>
                <div
                    className={classes.rightBold}>
                    <PriceInput
                        disabled={true}
                        id="priceNoVAT"
                        fullWidth={true}
                        value={noVat * rate}
                        inputComponent={numberFormatPriceCustom}
                        endAdornment={<InputAdornment
                            disableTypography
                            classes={{root: classes.rightCurrency}}
                            position="end">{selectedCurrency}</InputAdornment>}
                    />
                </div>
            </div>
                {Object.keys(vat).map(function (key, index) {
                    // key: the name of the object key
                    // index: the ordinal position of the key within the object
                    return (
                        <>
                            <div className={classes.receiptItemBig}>
                                <div className={classes.leftNormal}>{t('SUM_VAT', {rate: key})}</div>
                                <div
                                    className={classes.right}>
                                    <PriceInput
                                        fullWidth={true}
                                        disabled={true}
                                        value={(vat[key] || 0) * rate * (key / 100)}
                                        inputComponent={numberFormatPriceCustom}
                                        endAdornment={<InputAdornment
                                            disableTypography
                                            classes={{root: classes.rightCurrency}}
                                            position="end">{selectedCurrency}</InputAdornment>}
                                    />
                                </div>
                            </div>

                        </>
                    )
                })
                }
            <div className={classes.receiptItemBig}>
                <div className={classes.leftBig}>{t('TOTAL_PAY')}</div>
                <div
                    className={classes.rightBoldBig}>
                    <PriceInputBig
                        disabled={true}
                        id="priceWithVAT"
                        fullWidth={true}
                        value={(receipt && receipt.priceWithVAT ? receipt.priceWithVAT : 0) * rate}
                        inputComponent={numberFormatPriceCustom}
                        endAdornment={<InputAdornment
                            disableTypography
                            classes={{root: classes.rightBoldCurrency}}
                            position="end">{selectedCurrency}</InputAdornment>}
                    />
                </div>
            </div>
        </div>

    );
};


const UC_CURRENCY_CHANGE = 'UC0451';
const UC_ITEM_REMOVE = 'UC0452';
